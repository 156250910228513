.map {
    background-image: url('http://www.aljanh.net/data/archive/img/3333179192.png');
    background-size: cover;
    margin-left: 50px;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}
